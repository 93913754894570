<template>
    <!-- 子码导签名下载 -->
    <div id="export_signword_download_id">
        <!-- 头 -->
        <el-row class="head_top">
            <div class="head_top_back cursor"><i class="el-icon-arrow-left" @click="goBack()"></i></div>
            <div class="head_top_title">子码导签名下载</div>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">
            <!-- 查询条件 -->
            <el-row id="query_condition_id_202205200952" style="padding:0px 0;">
                <el-form  :model="form_data" :inline="true" style="margin-bottom: 0px;" label-width="68px">
                    <el-form-item label="通道">
                        <el-select v-model="form_data.channel_id" filterable size="mini" style="width:100%" placeholder="默认查询全部"> 
                            <el-option
                                v-for="item in channelDataList"
                                :key="item.id"
                                :label="item.channel_name + (item.id > 0 ?  '('+item.id+')' : '')"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="开始日期">
                        <el-date-picker
                            v-model="form_data.start_time"
                            type="date"
                            placeholder="选择日期" size="mini" style="width:125px;">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="结束日期">
                        <el-date-picker
                            v-model="form_data.end_time"
                            type="date"
                            placeholder="选择日期" size="mini" style="width:125px;">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submit_cha_xun()" size="mini">查询</el-button>
                    </el-form-item>
                </el-form>
            </el-row>
            <el-row style="overflow: auto;" :style="{height: ((content_heigth-query_condition_height)+'px')}">
                <el-table  :data="tableData" :show-header="true" border style="width: 100%" size="mini">
                    <el-table-column prop="channel_id" label="通道id" min-width="80" align="center"> </el-table-column>
                    <el-table-column prop="channel_name" label="通道" min-width="80" align="center"> </el-table-column>
                    <el-table-column prop="filename" label="文件名" min-width="80" align="center"> </el-table-column>
                    <el-table-column prop="createtime" label="生成时间" min-width="80" align="center"> </el-table-column>
                    <el-table-column label="操作" min-width="80" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini" @click="download(scope.row.id)">下载</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-row>
        </el-row>
    </div>
</template>
<script>
import API from '../../../api/api';
import {dateFtt, getItem} from '../../../api/tool.js';
import axios from 'axios';

export default {

    components: {
    },
    
    data(){
        return{
            content_heigth:500,//内容的高度
            content_width:500,//内容的宽度

            form_data:{
                channel_id: "",//查询条件-通道id 空字符表示全部
                start_time: new Date(),//开始日期
                end_time: new Date(),//结束日期
            },

            channelDataList:[],//通道列表数据-请求接口获取

            tableData:[],//表格数据
            query_condition_height:40,//查询条件的高度
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
    },
    //activated和deactivated配合keep-alive标签使用!
    activated() {
        //重置所有数据
	    Object.assign(this.$data, this.$options.data.call(this));
	    //初始化设置
        this.initSetUp();

        //获取通道列表数据-请求接口获取
        this.getChannelDataList();
        //查询
        this.submit_cha_xun();
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
            //console.log("content_heigth:"+this.content_heigth+" clientHeight:"+clientHeight);
        },
        //获取通道列表数据-请求接口获取
        getChannelDataList(){
            //请求接口
            API.ChannelServlet({
                param: "listAISelect",
                search:"",//输入框填写的查询条件
                style:"-1",//通道属性 -2:包括国内国际全部,-1:国内全部 0:移动 1：联通 2：电信
                send_type:"-1",//通道发送短信的类型 -1:所有 1：验证码 2：行业通知 3：营销 4：四类
                onoff:"-1",//状态 -1：全部 0：关闭 1：打开
                agreement:"-1",//协议类型 -1:全部 0：无相关协议；1：http1.0;2:http2.0;3:助通协议;101:cmpp2.0 102:cmpp3.0 103:SGIP1.0 104:SGIP1.2 105:SMGP
            }).then((res) => {
                if (res.recode === 0) {
                    this.channelDataList = res.list;//通道列表数据-指定通道组使用的--请求接口获取
                    var obj = {id: "", channel_name: "默认查询全部" };
                    this.channelDataList.unshift(obj);
                }
            });
        },
        //查询
        submit_cha_xun(){
            if(API.isEmtry(this.form_data.start_time)){
                this.$message.error("请选择开始日期");
                return;
            }
            if(API.isEmtry(this.form_data.end_time)){
                this.$message.error("请选择开始日期");
                return;
            }
            var start_time = dateFtt(this.form_data.start_time,"yyyyMMdd");
            var end_time = dateFtt(this.form_data.end_time,"yyyyMMdd");
            //请求接口
            API.OperationManagerServlet({
                param: "export_signword_list",
                channel_id: this.form_data.channel_id,
                start_time: start_time,
                end_time: end_time,
            }).then((res) => {
                if (res.recode === 0) {
                    this.tableData = res.list;

                    this.$nextTick(function(){
                        //查询条件的高度
                        var query_condition = document.getElementById('query_condition_id_202205200952');
                        if(!API.isEmtry(query_condition)){
                            this.query_condition_height = query_condition.clientHeight;
                        }
                    });
                } 
            });
        },
         //下载
        download(id) {
            let params = {
                param: "export_signword",
                id: id,
                callback: new Date().getTime(),
                session: getItem("qxt_session"),
            }
            //下载文件流
            let url = '/OperationManagerServlet'
            axios.post(url,params,{
                responseType:'blob'
            }).then(res => {
                const name = res.headers['content-disposition'];
                let fileName = '';
                if (name) {
                    const str1 = name.replace(' ', '');
                    const arr1 = str1.split(';');
                    arr1.some(item => {
                        if (item.indexOf('filename') != -1) {
                            fileName = item.split('=')[1];
                        }
                        return item.indexOf('filename') != -1;
                    });
                    if (fileName === ''){
                        fileName = 'export.csv'
                    }
                } else {
                    fileName = 'export.csv'
                }

                try {
                    let objectUrl1 = window.URL.createObjectURL(new Blob([res.data]));
                    let elink = document.createElement('a');
                    elink.setAttribute('download', decodeURI(decodeURI(fileName)));
                    elink.style.display = 'none';
                    elink.href = objectUrl1;
                    document.body.appendChild(elink);
                    elink.click();
                    document.body.removeChild(elink);
                    window.URL.revokeObjectURL(elink.href);
                    this.$message({showClose: true, message: '恭喜你，下载成功', type: 'success'});
                }catch (err){
                    this.$message.warning('下载失败!')
                }
            })
        },
        
        //返回
        goBack() {
            API.router_to("/export_signword_index");
        },
    }
};
</script>

<style scoped>
/*form表单样式调整*/
#app_check_rate_list>>>.el-form-item {
  margin-bottom: 0px;
}
</style>